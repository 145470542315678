<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="3" md="6">
        <ecommerce-earnings-chart :data="data.earningsChart" />
      </b-col>
      <b-col xl="9" md="6">
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="12" md="6" cols="12">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>

          <b-col lg="12" md="6">
            <b-row>
              <b-col lg="6" md="3" cols="6">
                <LeadsChartVue :data="data.statisticsOrder" />
              </b-col>
              <!--/ Bar Chart - Orders -->
              <b-col lg="6" md="3" cols="6">
                <ClientsChartVue :data="data.statisticsProfit" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ChartSales
          :sales="salesOrderList"
          :totalSales="totalSalesAmount"
          v-if="!loading"
        />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <SalesTable :sales="salesOrderList" v-if="!loading" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->

      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col lg="4" md="6">
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col> -->
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import ChartSales from "./ChartSales.vue";
import SalesTable from "./SalesTable.vue";
import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import LeadsChartVue from "./LeadsChart.vue";
import ClientsChartVue from "./ClientsChart.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    ChartSales,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    LeadsChartVue,
    SalesTable,
    ClientsChartVue,
  },
  data() {
    return {
      data: {},
      counter: 0,
      totalSalesAmount: 0,
    };
  },

  computed: {
    ...mapGetters("reportModule", {
      sales: "sales",
      loading: "loading",
    }),
    ...mapGetters("salesOrderModule", {
      salesOrderList: "salesorders",
      loading: "loading",
    }),
  },

  async mounted() {
    // if (localStorage.getItem("reloaded")) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem("reloaded");
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem("reloaded", "1");
    //   location.reload();
    // }
    await this.getSalesOrdersListAction()
      .then(() => {
        let totalSalesAmount = 0;
        this.salesOrderList.forEach((order) => {
          totalSalesAmount += order.total;
        });
        this.totalSalesAmount = totalSalesAmount;
      })
      .catch((response) => {
        console.log(response);
      });
  },

  computed: {
    ...mapGetters("salesOrderModule", {
      salesOrderList: "salesorders",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("reportModule", ["getSalesReportAction"]),
    ...mapActions("salesOrderModule", ["getSalesOrdersListAction"]),
    confirmText() {
      this.$swal({
        title: "Change the default password",
        text: "You still have the default password, are you willing to change it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push("/account-setting");
        }
      });
    },
  },

  created() {
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const userData = getUserData();
      this.data.congratulations.name =
        userData.fullName.split(" ")[0] || userData.username;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
