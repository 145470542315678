<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25"> Sales </b-card-title>
        <b-card-text class="mb-0">
          {{ currentYear }} Total Sales: Rs {{ totalSales.toFixed(2) }}
        </b-card-text>
      </div>
      <feather-icon icon="SettingsIcon" size="18" class="text-muted cursor-pointer" />
    </b-card-header>

    <b-card-body class="pb-0">
      <vue-apex-charts type="line" height="240" :options="chartOptions" :series="chartData" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
  },
  props: {
    sales: {
      type: Array,
      required: true,
    },
    totalSales: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'line',
          height: 350,
        },
        title: {
          text: 'Monthly Sales Data',
          
        },
        xaxis: {
          categories: Object.keys(this.monthlySalesData).sort((a, b) => new Date(a) - new Date(b)),
          labels: {
            style: {
              colors: '#000000', // Setting the color of the x-axis labels to black
            },
          },
        },
        yaxis: {
          title: {
            text: 'Sales Amount',
            style: {
              color: '#000000', // Setting the color of the y-axis title to black
            },
          },
          labels: {
            style: {
              colors: '#000000', // Setting the color of the y-axis labels to black
            },
          },
          tickAmount: 10,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return 'Rs ' + value.toFixed(2);
            },
          },
          style: {
            colors: '#000000', // Setting the color of the tooltip text to black
          },
        },
      };
    },

    chartData() {
      return [
        {
          name: 'Sales Amount',
          data: Object.values(this.monthlySalesData).map((data) => parseFloat((data.totalSales / data.count).toFixed(2))), // Averaging the sales and formatting it to 2 decimal places
        },
      ];
    },
    monthlySalesData() {
      return this.sales.reduce((acc, sale) => {
        const date = new Date(sale.createdOn);
        const month = date.getMonth();
        const year = date.getFullYear();
        const key = `${year}-${month + 1}`;

        if (!acc[key]) {
          acc[key] = { totalSales: 0, count: 0 };
        }

        acc[key].totalSales += sale.total;
        acc[key].count += 1;

        return acc;
      }, {});
    },
  },
};
</script>
