<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Performance</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0"> Updated 1 month ago </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
         
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  computed: {
    ...mapGetters("salesOrderModule", {
      salesOrderList: "salesorders",
      loading: "loading",
    }),
    ...mapGetters("customerModule", {
      customerList: "customers",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      productList: "product",
      loading: "loading",
    }),
  },

  methods: {
    ...mapActions("customerModule", ["getCustomersListAction"]),
    ...mapActions("salesOrderModule", ["getSalesOrdersListAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
    getSalesOrderCount() {
      this.data[0].title = this.salesOrderList.length;
    },

    getSalesAmount() {
      let totalAmount = 0;
      this.data[3].title = this.salesOrderList.forEach((item) => {
        totalAmount += item.total;
      });
      this.data[3].title = "Rs " + totalAmount.toFixed(2);
    },
  },

  async mounted() {
    await this.getSalesOrdersListAction().then(() => {
      this.getSalesOrderCount();
      this.getSalesAmount();
    });

    await this.getCustomersListAction().then(() => {
      this.data[1].title = this.customerList.length;
    });

    await this.getProductListAction().then(() => {
      let product = this.productList.filter((item) => item.type !== 4);
      this.data[2].title = product.length;
    });
  },

  data() {
    return {
      data: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: null,
          subtitle: "Orders",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: null,
          subtitle: "Customers",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: null,
          subtitle: "Products",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: null,
          subtitle: "Sales",
          customClass: "",
        },
      ],
    };
  },
};
</script>
