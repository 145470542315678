<template>
  <b-card v-if="data" no-body>
    <b-card-header>
      <h4 class="mb-0">Production Management</h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer" />
      </b-card-text>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="[percentStatus2]"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> Completed </b-card-text>
        <h3 class="font-weight-bolder mb-0">{{ completed }}</h3>
      </b-col>
      <b-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> In Progress </b-card-text>
        <h3 class="font-weight-bolder mb-0">{{ inProgress }}</h3>
      </b-col>

      <b-col cols="4" class="border-top d-flex align-items-between flex-column py-1">
        <b-card-text class="text-muted mb-0"> Not Started </b-card-text>
        <h3 class="font-weight-bolder mb-0">{{ noStarted }}</h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapGetters, mapActions } from "vuex";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#51e5a8";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("jobcardModule", {
      jobcardList: "jobcards",
      loading: "loading",
    }),
    completed() {
      let countStatus = 0;
      this.jobcardList.forEach((obj) => {
        if (obj.status == 2) {
          countStatus++;
        }
      });
      return countStatus;
    },

    noStarted() {
      let countStatus = 0;
      this.jobcardList.forEach((obj) => {
        if (obj.status == 0) {
          countStatus++;
        }
      });
      return countStatus;
    },

    inProgress() {
      let countStatus = 0;
      this.jobcardList.forEach((obj) => {
        if (obj.status == 1) {
          countStatus++;
        }
      });
      return countStatus;
    },
    percentStatus2() {
      let countStatus2 = 0;
      this.jobcardList.forEach((obj) => {
        if (obj.status == 2) {
          countStatus2++;
        }
      });
      let percent = (countStatus2 / this.jobcardList.length) * 100;

      percent = parseInt(percent.toFixed(0));
      return isNaN(percent) ? 0 : percent;
    },
  },
  methods: {
    ...mapActions("jobcardModule", ["getJobcardListAction"]),
  },
  async mounted() {
    await this.getJobcardListAction().then(() => {
      this.jobcardList.forEach((obj) => {
        if (obj.status == 2) {
          this.completed++;
        } else {
          this.noStarted++;
        }
      });
    });
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    };
  },
};
</script>
