<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="12">
        <b-card-title class="mb-1"> Customers </b-card-title>
        <div class="font-medium-2">This Month</div>
        <h5 class="mb-1">{{ customerCountForCurrentMonth }}</h5>
        <b-card-text class="text-muted font-small-2">
          <span> Number of customers this month.</span>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },

  computed: {
    ...mapGetters("customerModule", {
      customerList: "customers",
      loading: "loading",
    }),

    customerCountForCurrentMonth() {
      let today = new Date();
      let currentMonth = today.getMonth();
      let customerCount = 0;

      this.customerList.forEach((customer) => {
        let customerDate = new Date(customer.createdOn);
        if (customerDate.getMonth() === currentMonth) {
          customerCount++;
        }
      });
      return customerCount;
    },
  },

  async mounted() {
    await this.getCustomersListAction();
  },

  methods: {
    ...mapActions("customerModule", ["getCustomersListAction"]),
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Company", "Individual"],
      },
      chartData: [
        {
          name: "Customers",
          data: [
            {
              name: "Company",
              value: 20,
            },
            {
              name: "Individual",
              value: 80,
            },
          ],
        },
      ],
    };
  },
};
</script>
